import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout/layout';
import Seo from '../../components/layout/seo';
import BlogPage from '../../components/content_type/Blog/BlogPage';
import { stripHtml } from 'string-strip-html';

const NodeBlogTemplate = ({ data }) => {
	const edge = data.blog.edges;
	const noHtmlDesc = (html) => {
		return stripHtml(html).result;
	};

	return (
		<Layout>
			<>
				{edge.map((item) => (
					<div>
						<Seo
							title={item.node.r.entity_id.title}
							description={noHtmlDesc(item.node.r.entity_id.body.processed)}
							summary={item.node.r.entity_id.summary}
							keywords={[`news`]}
							image={item.node.r.entity_id.r.media.r.image.uri.url}
							url={process.env.SITE_URL + item.node.r.entity_id.path.alias + "/"}
						/>
						<BlogPage data={data} />
					</div>
				))}
			</>
		</Layout>
	);
};

export default NodeBlogTemplate;

export const query = graphql`
	query blog($slug: Int, $gid: Int) {
		blog: allGroupContentLodgeGroupNodeBlog(
			filter: { drupal_internal__id: { eq: $slug } }
		) {
			edges {
				node {
					...nodeBlogFragment
				}
			}
		}
		files: allFileFile {
			edges {
				node {
					style: image_style_uri {
						...imageStyleFragment
					}
					drupal_id
				}
			}
		}
		lodge: groupLodge(drupal_internal__id: { eq: $gid }) {
			...lodgeFragment
		}
	}
`;
