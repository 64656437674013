import React from 'react';
import Content from '../../fields/Content';
import Body from '../../fields/Body';
import Image from '../../fields/Image';
import DateSingle from '../../fields/DateSingle';

const BlogPage = (props) => {
	const blog = props.data.blog.edges[0].node.r.entity_id;
	const media = blog.r.media;

	return (
		<div>
			<div className=''>
				<Image
					image={media.r.image}
					alt={blog.title}
					className='h-96 object-cover object-center inset-0 w-full lg:static'
					sizes='600w'
					src={media.r.image.uri.url}
				/>
			</div>

			<div className='relative max-w-4xl mx-auto py-16 px-4 sm:px-6 lg:px-8'>
				<div className='font-title text-center text-lg max-w-prose mx-auto pb-6 lg:pb-12'>
					<p className='text-sm md:text-base text-center leading-6 text-ap-purple font-semibold  uppercase'>
						{blog.subhead}
					</p>
					<h1 className='font-title my-2 text-2xl md:text-3xl leading-8 font-extrabold text-gray-900 sm:text-4xl sm:leading-10'>
						{blog.title}
					</h1>
					<DateSingle
						thisDate={blog.created}
						classes='font-body font-semibold text-sm sm:my-1 lg:my-0 text-gray-400'
					/>
				</div>
				<div className='font-body text-base text-gray-600 mx-auto'>
					<Body data={blog.body.processed} files={props.data.files} />
					<Content content={blog.r.content} />
				</div>
			</div>
		</div>
	);
};

export default BlogPage;
